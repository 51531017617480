import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '141, 11, 14',
		'primary-dark': '94, 6, 8',
		'accent': '255, 211, 204',
		'accent-plus': '94, 6, 8',
	},
});
